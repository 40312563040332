<template>
  <div>
    <el-empty v-if="!loginCheck" description="暂无数据"></el-empty>
    <div v-if="loginCheck">
      个人中心
    </div>
  </div>
</template>

<script>
import token from "@/api/token"
export default {
  name: "Personal",
  data() {
    return {
      loginCheck: false
    }
  },
  created() {
    if (token.getToken()) {
      this.loginCheck = true
    } else {
      this.$message({
        message: '请登录后，在进行操作',
        type: 'warning'
      })
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
